var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "view-producer" }, [
    _vm._m(0),
    _c("section", { staticClass: "section" }, [
      _vm.isLoading
        ? _c(
            "div",
            { staticClass: "container container--fullWidth" },
            [_c("VLoadSpinner")],
            1
          )
        : _c(
            "div",
            { staticClass: "container container--fullWidth grid grid__col4" },
            [
              _vm._l(_vm.coupons, function (coupon, index) {
                return _c("box-coupon", {
                  key: index,
                  attrs: { coupon: coupon, index: index },
                  on: {
                    callUpdateCoupon: _vm.setUpdatedPropertyOnCoupon,
                    callRemoveCoupon: _vm.openConfirmActionModal,
                  },
                })
              }),
              _c(
                "a",
                {
                  staticClass:
                    "box box--borderDashed box--sm box--minHeight box--flexCenter",
                  on: { click: _vm.addBlankCoupon },
                },
                [
                  _c(
                    "div",
                    [
                      _c("icon", {
                        staticClass: "box__mainIcon",
                        attrs: { name: "add" },
                      }),
                      _c("h2", [_vm._v("New Coupon")]),
                    ],
                    1
                  ),
                ]
              ),
            ],
            2
          ),
    ]),
    _c("section", { staticClass: "section__bottom-button" }, [
      _c(
        "a",
        { staticClass: "btn btn--primary", on: { click: _vm.saveCoupons } },
        [_vm._v("SAVE CHANGES")]
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("header", { staticClass: "header" }, [
      _c("div", { staticClass: "container headerContainer" }, [
        _c("div", { staticClass: "headerContainer__heading" }, [
          _c("h1", [_vm._v("Coupons")]),
          _c("p", [
            _vm._v(
              "You might get errors due to the coupon code being taken already, prefix your coupon codes with your initials to make it unique i.e. "
            ),
            _c("strong", [_vm._v("EG_SUMMER50")]),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }